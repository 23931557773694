<template>
  <div class="filters-sidebar">
    <div
      v-if="selectedBetTicker && !activeFilters?.length && !inactiveFilters?.length"
      class="filters-sidebar__empty"
    >
      There are no selected filters. Click More filters to start adding them.
    </div>
    <div
      v-if="!selectedBetTicker"
      class="filters-sidebar__empty"
    >
      Please select bet ticker before creating filters.
    </div>
    <FiltersListing
      v-if="inactiveFilters.length"
      :filters="inactiveFilters"
      filters-label="INACTIVE FILTERS"
    />
    <FiltersListing
      v-if="activeFilters.length"
      :filters="activeFilters"
      filters-label="ACTIVE FILTERS"
    />
    <div class="filters-listing__footer">
      <div class="bet-ticker-filter-button-group">
        <div
          class="bet-ticker-filter-button save"
          @click="saveFilters"
        >
          Save
        </div>
        <div
          class="bet-ticker-filter-button cancel"
          @click="cancelFilters"
        >
          Cancel
        </div>
      </div>
      <div
        class="bet-ticker-filter-button"
        ref="moreFiltersRef"
        @click="() => { moreFiltersToggled = !moreFiltersToggled; }"
      >
        <div class="icon-label-wrapper">
          <Icon name="plus" />
          More filters
        </div>
        <Icon name="chevron-selector" />
        <div
          class="more-filters-dropdown"
          v-if="moreFiltersToggled"
        >
          <div
            class="more-filters-dropdown-option"
            v-for="option in filtersOptions"
            @click="toggleFilters(option)"
            :key="option.id"
          >
            <div
              class="more-filters-dropdown-checkbox-item"
              @click="toggleFilters(option)"
            >
              <Checkbox
                :model-value="isFilterAdded(option)"
                @update:modelValue="toggleFilters(option)"
              />
              {{ option.label }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { onClickOutside } from '@vueuse/core';
import { find } from 'lodash';
import Icon from '@/components/common/Icon';
import Checkbox from '@/components/common/Checkbox';
import FiltersListing from './FiltersListing';
import { filtersOptions } from '@/services/helpers/bet-ticker-filters';

export default {
  components: {
    FiltersListing,
    Icon,
    Checkbox,
  },
  setup() {
    const store = useStore();

    const moreFiltersToggled = ref(false);
    const moreFiltersRef = ref(null);

    const selectedBetTicker = computed(() => store.getters['betTicker/selectedBetTicker']);
    const activeFilters = computed(() => selectedBetTicker.value?.filters || []);
    const inactiveFilters = computed(() => selectedBetTicker.value?.inactiveFilters || []);

    const selectedOddFormat = computed(() => store.getters.selectedOddFormat?.name || 'american');

    const isFilterAdded = (filter) => !!(find(activeFilters.value, { type: filter.id }) || find(inactiveFilters.value, { type: filter.id }));
    const toggleFilters = (filter) => {
      const filterPayload = {
        type: filter.id,
      };
      store.dispatch('betTicker/toggleFilters', filterPayload);
    };

    const saveFilters = () => {
      store.dispatch('betTicker/saveFilters', selectedOddFormat.value);
    };

    const cancelFilters = () => {
      store.dispatch('betTicker/closeFilters', selectedBetTicker.value?.betTickerId);
    };

    onClickOutside(moreFiltersRef, () => {
      moreFiltersToggled.value = false;
    });

    return {
      selectedBetTicker,
      activeFilters,
      inactiveFilters,
      filtersOptions,
      moreFiltersToggled,
      moreFiltersRef,
      isFilterAdded,
      toggleFilters,
      saveFilters,
      cancelFilters,
    };
  },
};
</script>

<style lang="scss">
.filters-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 20px 0 0 0;

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 32px 16px 0;
    gap: 32px;
    text-align: center;
    max-width: 240px;
    margin: 0 auto;
  }

  .filters-listing__footer {
    height: 64px;
    width: 100%;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #F0F0F0;

    .bet-ticker-filter-button-group {
      display: flex;
      height: 100%;
      align-items: center;
      gap: 4px;
    }

    .bet-ticker-filter-button {
      display: flex;
      justify-content: space-between;
      padding: 0 8px;
      align-items: center;
      height: 32px;
      border: 1px solid #F0F0F0;
      width: 130px;
      cursor: pointer;
      position: relative;

      &.cancel {
        border: 0;
        width: auto;
        padding: 0 16px;
      }

      &.save {
        padding: 0 16px;
        border: 0;
        width: auto;
        background-color: #003C3C;
        border-color: #003C3C;
        color: #fff;
        border-radius: 4px;
      }

      .icon {
        width: 12px;
        height: 12px;
      }

      .icon-label-wrapper {
        display: flex;
        height: 100%;
        align-items: center;

        .icon {
          margin-right: 4px;
        }
      }

      .more-filters-dropdown {
        position: absolute;
        bottom: 54px;
        right: 0;
        min-height: 30px;
        width: 240px;
        border: 1px solid #F0F0F0;
        box-shadow: 0px 2px 4px 0px #19141414;

        .more-filters-dropdown-option {
          height: 32px;
          padding: 0 8px;
          display: flex;
          align-items: center;

          .more-filters-dropdown-checkbox-item {
            display: flex;
            height: 100%;
            align-items: center;

            .checkbox {
              margin-right: 4px;
            }
          }
        }
      }
    }
  }
}
</style>
