<template>
  <div class="filters-form-condition-select">
    <ul class="filters-form-condition-select__menu">
      <li
        v-for="option in options"
        :key="option[idKey]"
        class="filters-form-condition-select__menu-option"
        tabindex="0"
        @click="updateCondition(option[idKey])"
      >
        <RadioInput
          :checked="condition === option[idKey]"
        />
        {{ option.label }}
      </li>
      <li class="filters-form-condition-select__menu-option">
        <div class="filters-form-condition-select__input">
          <input
            class="filters-form-condition-select__input-field"
            :value="parameter"
            @blur="updateParameter($event.target.value)"
          >
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { isFinite } from 'lodash';
import RadioInput from '@/components/common/RadioInput';

export default {
  components: {
    RadioInput,
  },
  props: {
    condition: {
      type: String,
      required: true,
    },
    parameter: {
      type: Number,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    idKey: {
      type: String,
      default: 'id',
    },
  },
  emits: {
    'update:condition': {
      type: String,
    },
    'update:parameter': {
      type: Number,
    },
  },
  setup(_, { emit }) {
    const updateCondition = (newCondition) => {
      emit('update:condition', newCondition);
    };
    const updateParameter = (newParameter) => {
      const newNumericParameter = +newParameter;
      if (!isFinite(newNumericParameter)) {
        emit('update:parameter', 0);
        return;
      }
      emit('update:parameter', newNumericParameter);
    };
    const clear = () => {
      updateCondition('');
      updateParameter(0);
    };

    return {
      updateCondition,
      updateParameter,
      clear,
    };
  },
};
</script>

<style lang="scss">
.filters-form-condition-select {
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;

  &__input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &-field {
      position: relative;
      background-color: #fff;
      border: 1px solid #F0F0F0;
      padding: 8px;
      width: 100%;
      height: 32px;
      border-radius: 4px;
      cursor: pointer;

      &::placeholder {
        color: #CDCDCD;
      }

      &:focus {
        outline: 1px solid #a2bfff;
      }
    }

    &-button {
      position: absolute;
      right: 8px;

      &--is-clear-button {
        stroke: #000;
        cursor: pointer;
      }

      &--is-chevron-button {
        pointer-events: none;
      }
    }
  }

  &__menu {
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    overflow-y: auto;
    z-index: 9000;
    display: flex;
    flex-direction: column;
    border-radius: var(--radius-medium, 4px);
    border: 1px solid var(--neutral-stroke-default-resting, #F0F0F0);
    background: var(--neutral-bg-default-resting, #FFF);
    box-shadow: 0px 2px 4px 0px rgba(25, 20, 20, 0.08);
    margin-top: 4px;
    padding: 4px 0;
    user-select: none;

    &-option {
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;
      font-family: 'Rubik', sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      height: 32px;
      padding: 8px;

      &:hover,
      &:focus {
        background-color: #FAFAFA;
        outline: none;
      }

      .radio-input {
        width: 16px;
        height: 16px;
      }
    }
  }
}
</style>
