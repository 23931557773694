<template>
  <div class="filters-listing">
    <div class="filters-listing__form-group">
      <div class="filters-listing__form-label">
        {{ filtersLabel }}
      </div>
      <ul class="filters-listing__list">
        <li
          v-if="!filters.length"
          class="filters-listing__message"
        >
          There aren't any filters.
        </li>
        <FiltersListingItem
          v-for="(filterItem, index) in filters"
          :key="index"
          :filter-item="filterItem"
          :header-label="formatBetTickerFilterHeaderLabel(filterItem)"
          :label="formatBetTickerFilterLabel(filterItem) || 'N/A'"
          @remove="handleRemove(filterItem)"
        />
      </ul>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { formatBetTickerFilterLabel, formatBetTickerFilterHeaderLabel } from '@/services/helpers/bet-ticker-filters';
import FiltersListingItem from './FiltersListingItem';

export default {
  components: {
    FiltersListingItem,
  },
  props: {
    filters: {
      type: Array,
      required: true,
    },
    filtersLabel: {
      type: String,
      required: true,
    },
  },
  setup() {
    const store = useStore();

    const handleRemove = (filter) => {
      store.dispatch('betTicker/toggleFilters', filter);
    };

    return {
      formatBetTickerFilterLabel,
      formatBetTickerFilterHeaderLabel,
      handleRemove,
    };
  },
};
</script>

<style lang="scss">
.filters-listing {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 32px;

  &__form {
    &-group {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding: 0 16px;
    }

    &-label {
      display: flex;
      align-items: center;
      gap: 4px;
      font-family: 'Rubik', sans-serif;
      font-size: 10px;
      font-weight: 600;
      line-height: 11.85px;
      color: #175FFF;
      text-transform: uppercase;

      .icon {
        display: inline-block;
        fill: #6D6D6D;
        width: 12px;
        height: 12px;
        max-width: 12px;
        max-height: 12px;
      }

      .tooltip {
        text-transform: none;
        font-weight: 400;
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__message {
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
    padding: 16px;
    height: 64px;
    border-top: 1px solid #F0F0F0;

    .button {
      min-width: 130px;
      text-align: center;
      justify-content: center;
      margin-left: auto;

      .icon {
        svg {
          path {
            stroke: #fff;
          }
        }
      }
    }
  }
}
</style>
