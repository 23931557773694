<template>
  <div
    :class="['flag-cell', bet.multibet && 'multibet', bet.isBetPart && 'bet-part']"
  >
    <div
      v-if="bet.multibet"
      :class="['left-part ', bet.toggled && 'toggled']"
      @click="toggleParlayBet"
    >
      <Icon name="chevron-right" />
    </div>
    <div
      @click="toggleFlag"
      class="right-part"
      v-if="!bet.isBetPart"
    >
      <Icon
        :name="isFlagged ? 'flag-fill' : 'flag'"
      />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    bet: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const isFlagged = computed(() => props.bet.isFlagged);

    const toggleParlayBet = () => store.dispatch('betTicker/toggleParlayBet', props.bet.betId);
    const toggleFlag = () => store.dispatch('betTicker/toggleFlag', { betId: props.bet.betId, isFlagged: isFlagged.value });

    return {
      isFlagged,
      toggleParlayBet,
      toggleFlag,
    };
  },
};
</script>

<style lang="scss">
.flag-cell {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-right: 1px solid $gray500;
  .left-part, .right-part {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .left-part {
    width: calc(50% - 4px);
    margin-left: 4px;

    & .icon {
      stroke: $black;
    }
    &.toggled .icon {
      transform: rotate(90deg);
    }
  }

  &.multibet {
    justify-content: space-between;
  }
  &.multibet, &.bet-part {
    &::after {
      content: '';
      display: block;
      width: 8px;
      height: 100%;
      background-color: $brandPrimary500;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  &.bet-part {
    &::after {
      background-color: #99b2b1;
    }
  }
}
</style>
