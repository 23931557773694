<template>
  <li
    class="filters-listing-item"
    ref="itemMenuRef"
  >
    <div class="filters-listing-item-header">
      {{ headerLabel }}
    </div>
    <div
      class="filters-listing-item__button"
      @click.stop="handleClick"
    >
      <div class="filters-listing-item__button-label">
        {{ label }}
      </div>
      <div class="filters-listing-item__button__icons">
        <Icon name="chevron-selector" />
        <Icon
          class="remove-item"
          name="x"
          @click.stop="handleRemove"
        />
      </div>
    </div>
    <FiltersConditionSelect
      v-if="isMenuToggled"
      id="condition-select"
      :condition="filterItem.conditionType || ''"
      :parameter="filterItem.parameter || 0"
      :options="conditionTypeOptions"
      @update:condition="setConditionType"
      @update:parameter="setParameter"
    />
  </li>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { onClickOutside } from '@vueuse/core';
import Icon from '@/components/common/Icon';
import FiltersConditionSelect from './FiltersConditionSelect';
import { conditionTypeOptions } from '@/services/helpers/bet-ticker-filters';

export default {
  components: {
    Icon,
    FiltersConditionSelect,
  },
  props: {
    headerLabel: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    filterItem: {
      type: Object,
      required: true,
    },
  },
  emits: {
    click: {},
    remove: {},
  },
  setup(props, { emit }) {
    const store = useStore();
    const itemMenuRef = ref(null);
    const isMenuToggled = ref(false);
    const toggleDropdownMenu = () => {
      isMenuToggled.value = !isMenuToggled.value;
    };

    const handleClick = () => {
      toggleDropdownMenu();
    };
    const handleEdit = () => {
      handleClick();
    };
    const handleRemove = () => {
      emit('remove');
    };

    const updateFilter = (updatedFields) => {
      store.dispatch('betTicker/updateFilters', { ...props.filterItem, ...updatedFields });
    };
    const setConditionType = (newConditionType) => {
      updateFilter({ conditionType: newConditionType });
    };
    const setParameter = (newParameter) => {
      updateFilter({ parameter: newParameter });
      isMenuToggled.value = false;
    };

    onClickOutside(itemMenuRef, () => {
      isMenuToggled.value = false;
    });

    return {
      toggleDropdownMenu,
      isMenuToggled,
      handleClick,
      handleEdit,
      handleRemove,
      conditionTypeOptions,
      itemMenuRef,
      setConditionType,
      setParameter,
    };
  },
};
</script>

<style lang="scss">
.filters-listing-item {
  display: flex;
  align-items: center;
  gap: 4px;
  flex-direction: column;

  .filters-listing-item-header {
    width: 100%;
    color: #6D6D6D;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 600;
  }

  &__selector {
    cursor: move;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border: 1px solid #F0F0F0;
    border-radius: 4px;
    padding: 12px;
    width: 100%;
    height: 32px;
    cursor: pointer;
    position: relative;

    &-label {
      display: flex;
      align-items: center;
    }

    &__icons {
      display: flex;
      .icon {
        width: 16px;
        height: 16px;
        margin-left: 4px;
        cursor: pointer;

        &.remove-item {
          svg {
            stroke: #191414;
          }
        }
      }
    }
  }
}
</style>
